.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.noBottomLine .sapMPanelHdr,
.noBottomLine .sapMPanelContent,
.noBottomLine .sapMITBContent,
.noBottomLine.sapMLIB {
  border-bottom: none;
}

.noPointer.sapMLIB {
  cursor: default;
}

.sapMFlexBoxAlignItemsCenter.sapMVBox > .sapMFlexItem.max-height-100p {
  max-height: 100%;
}

.bsgSupportHeader.sap_fiori_3 {
  background-color: var(--sapShell_Category_15_Background);
  box-shadow: var(--sapShell_Category_15_BorderColor);
}

.bsgSupportHeader ui5-button#openSettingsBtn {
  max-width: 10rem;
}

.bsgSupportHeader #countdown-text {
  font-size: 1rem;
}

.bsgSupportHeader,
.bsgSupportFooter,
.bsgSupportPage::part(content),
.bsgSupportHelpDialog::part(header),
.bsgSupportHelpDialog::part(footer) {
  padding: 0;
}

.bsgSupportHeader::part(startContent),
.bsgSupportFooter::part(startContent) {
  padding-inline-start: 0.75rem;
}

.bsgSupportHeader::part(endContent),
.bsgSupportFooter::part(endContent) {
  padding-inline-end: 0.75rem;
}

.bsgSupportHeader.sap_fiori_3 ui5-title,
.bsgSupportHeader.sap_fiori_3 #countdown-text {
  color: var(--sapShell_Category_15_TextColor);
  text-shadow: var(--sapShell_Category_15_TextShadow);
}

#homeBtn.sap_fiori_3,
#openMenuBtn.sap_fiori_3,
#openRecorderBtn.sap_fiori_3,
#openSettingsBtn.sap_fiori_3 {
  background-color: transparent;
  color: var(--sapIndicationColor_9_TextColor);
}

#homeBtn.sap_fiori_3:hover,
#openMenuBtn.sap_fiori_3:hover,
#openRecorderBtn.sap_fiori_3:hover,
#openSettingsBtn.sap_fiori_3:hover {
  background-color: var(--sapShell_Category_9_Background);
}

.bsgSupportFooter::before {
  content: "Copyright © BSG PARTNERS Co., Ltd.";
  font-size: 1rem;
  line-height: 3rem;
  margin-bottom: -3rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
  color: var(--sapTextColor);
}

@media screen and (device-width <= 375px) {
  .bsgSupportHeader ui5-button#openMenuBtn {
    font-size: 0.8rem;
  }

  .bsgSupportHeader ui5-button#openSettingsBtn {
    font-size: 0.8rem;
    height: 1rem;
    width: 6rem;
  }

  .bsgSupportHeader ui5-button#openSettingsBtn button ui5-icon {
    width: 1rem;
  }

  .bsgSupportHeader #countdown-status {
    position: fixed;
    top: -0.7rem;
    right: 0;
    width: 6rem;
  }

  .bsgSupportHeader
    #countdown-status
    [data-component-name="ObjectStatusIconContainer"] {
    height: 0.8rem;
  }

  .bsgSupportHeader
    #countdown-status
    [data-component-name="ObjectStatusIconContainer"]
    ui5-icon {
    height: 0.8rem;
  }

  .bsgSupportHeader #countdown-text {
    font-size: 0.8rem;
  }

  .bsgSupportFooter ui5-link {
    font-size: 0.8rem;
  }

  .bsgSupportFooter .appVersion {
    font-size: 0.8rem;
    margin-top: 2rem;
    margin-right: -5rem;
    opacity: 0.8;
  }

  .bsgSupportFooter::before {
    width: 150px;
    font-size: 0.8rem;
    margin-bottom: -2.3rem;
    line-height: 1rem;
  }
}

/** HEIGHT SIZING HELPER **/

.h-100 {
  height: 100% !important;
}

.h-0 {
  height: 0 !important;
}

.mh-25 {
  min-height: 25% !important;
}

.flex-basis-half {
  flex-basis: 25% !important;
}

.flex-basis-third {
  flex-basis: 33.3333% !important;
}

/* Hide except div#root under div#sap-ui-preserve */
div[aria-hidden="true"]#sap-ui-preserve > :not(#root) {
  display: none;
}

.paddingLeft2rem {
  padding-left: 2rem;
}

/* TABLE SCROLL FIX */
.h-100 > div.sapMScrollContScroll {
  width: 100%;
  height: 100%;
}

/* MOBILE VIEW FIX */
.sapFDynamicPageContent > div {
  height: 100%;
}

/* Error Log Text Overflow Issue Fix */
.sapMMsgViewDescriptionText {
  word-break: break-all;
}

.ft-login-form > div > div {
  border-radius: 1rem;
}

/* React Component Root Height&Position */
div#root {
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

div#content {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}

/* TEMPORARILY CONSIDER REMOVE LATER */
.sapMTableTH.sapMListTblSelCol .sapMCb.sapMLIBSelectM {
  display: none;
}

.select-all .sapMTableTH.sapMListTblSelCol .sapMCb.sapMLIBSelectM {
  display: inline-block;
}

.hide-second-level
  div[aria-level="2"].sapUiTableContentRow
  .sapUiTableRowSelectionCell {
  pointer-events: none !important;
  opacity: 0;
}

.normal-font-weight .sapMObjectIdentifierTitle .sapMText {
  font-weight: normal;
  font-size: 0.875rem;
}

.ft-normal-font-weight .sapMObjectIdentifierTitle .sapMText {
  font-weight: normal;
}

.sync-height-with-selection-mark .sapMObjectIdentifierTextBellow {
  margin-top: -0.25rem;
}

.sync-height-with-selection-mark .sapMObjectIdentifierTextBellow .sapMText {
  font-size: 0.75rem;
}

.set-content-padding-1rem .sapMDialogScrollCont {
  padding: 1rem;
}

/* Production Schedule, Form checkbox padding fix */
.pp_checkbox_pad {
  padding: 0 15px 0 30px;
}

.pal_sales_column {
  color: #f9b07f !important;
}

.pal_purcahse_column {
  color: green !important;
}

.pal_profit {
  color: #cc3f0f !important;
}

.display-selectall-box {
  display: block !important;
}

.sapUiBlockLayer {
  z-index: 2;
}

ul.pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

ul.pagination li {
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
}

ul.pagination li a {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  outline: none;
  margin: 0;
}

ul.pagination li.previous {
  margin-right: 1rem;
}

ul.pagination li.next {
  margin-left: 1rem;
}

ul.pagination li.active {
  font-weight: bold;
  background-color: rgb(70 128 236);
  border-radius: 2px;
  color: #fff;
}

.PaddingTopBottom1Over4REM {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

ui5-dialog
  div[class^="ui5wcr-AnalyticalTable-"][role="table"]
  > div[name="partOfTable"],
ui5-dialog
  div[class^="AnalyticalTable--"][role="table"]
  > div[name="partOfTable"] {
  top: calc(var(--sapWcrBarHeight) - 32px - 1px);
}

ui5-dialog::part(header),
ui5-popover::part(header) {
  padding: 0;
}

ui5-dialog div[slot="footer"] ui5-button {
  padding: 1rem;
  margin: 0.5rem;
  font-weight: bold;
}

div.dialog-h-100 div.sapMDialogScroll {
  height: 100%;
}

div.dialog-h-100 div.sapMDialogScrollCont {
  height: 100%;
}

.fields-relative-position.sapUiForm
  .sapUiRespGridMedia-Std-Tablet.sapUiRespGridHSpace0
  > .sapUiRespGridSpanM5 {
  width: auto;
}

.sapUiBLy {
  background-color: rgb(247 247 247 / 72%);
}

.node .description {
  position: absolute;
  top: 28px;
  margin: 0 14px;
  padding: 0 4px;
  width: calc(100% - 38px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #3980ef;
  background-color: #e3e600;
  border-radius: 5px;
  line-height: 26px;
  font-size: 1rem;
}

.node .description:hover {
  overflow: visible;
  white-space: normal;
  color: #0048bd;
}

.disabled.sapMInputBaseContentWrapper {
  border-color: #dde7f3;
}

.container-fit-content .sapUiFormResGridCont {
  width: fit-content;
  min-width: 18rem;
}

.container-fit-content
  div.sapUiRespGridOverflowHidden[class*="sapUiRespGrid"]
  > div[class*="sapUiRespGridSpan"] {
  overflow: initial;
}

.container-fit-content
  > .sapUiFormResGrid
  > .sapUiRespGrid:not(.sapUiRespGridMedia-Std-Phone)
  > div {
  width: auto;
  padding-left: 0.75rem;
  padding-right: 0;
}

.sapUiFormResGrid
  > .sapUiRespGrid:not(.sapUiRespGridMedia-Std-Phone)
  > div
  > .sapUiRGLContainer
  > .sapUiFormTitle
  > .sapMBtn {
  display: none;
}

div#react_content {
  overflow: auto;
  height: 100%;
}

div#react_content *[data-component-name="FormGroupTitle"] {
  padding-left: 2.5rem;
  font-weight: bold;
}

/* react_content는 Auth를 통과하는 컴포넌트에게만 생성되는데, Auth 안에 있지 않은 리액트 페이지의 경우 content가 덮어쓰는 오류가 생겨서
이를 고치기 위해 renderJSX에 content보다 위에 오는 css 코드를 추가 */
div.renderJSX {
  position: relative;
  max-height: 100%;
  overflow-y: auto;
}

.no-indent-row .sapMListTblHighlightCell,
.no-indent-row .sapMListTblHighlightCol {
  width: 1rem;
}

.tiny-margin-items.sapMHBox > .sapMFlexItem {
  margin-right: 0.5rem;
}

.tiny-margin-items.sapMHBox
  > .sapMFlexItem
  > .sapMInputBase:not(.sapMInputBaseHasEndIcons, .sapMInputBaseHasBeginIcons)
  .sapMInputBaseContentWrapper {
  min-width: auto;
}

.allow-scroll.sapMScrollCont > .sapMScrollContScroll {
  width: max-content;
  min-width: 100%;
}

.allow-scroll.sapMScrollCont > .sapMScrollContScroll .sapMIBar.ft-pagination {
  width: calc(100vw - 0.75rem);
  left: 0;
  position: sticky;
}

.allow-scroll.sapMListTblCnt {
  width: max-content;
  min-width: 100%;
}

.allow-scroll.sapMListTblCnt > .sapMListTbl {
  width: max-content;
  min-width: 100%;
}

.zoom100 {
  zoom: 100%;
}

.zoom125 {
  zoom: 125%;
}

.zoom150 {
  zoom: 150%;
}

.zoom175 {
  zoom: 175%;
}

.zoom200 {
  zoom: 200%;
}

.sapMListTblCell > .sapMObjectNumber {
  text-align: right !important;
}

.sapMObjectNumber.hideUnit .sapMObjectNumberUnit {
  display: none;
}

.ft-transition-for-hide {
  transition:
    opacity 0.2s,
    height 0.1s;
  transition-timing-function: ease-in;
  height: 100%;
}

.sapMDialogStretchContent > .ft-transition-for-hide {
  height: auto;
}

.ft-transition-for-hide.ft-hide-with-effect {
  transition:
    opacity 0.1s,
    height 0.1s;
}

.ft-hide-with-effect {
  overflow: hidden;

  /* display: none; */
  opacity: 0;
  height: 0;
}

.ft-notify-form-is-hidden {
  animation: flash ease-out 2s infinite;
}

@keyframes flash {
  0% {
    opacity: 1;
    box-shadow: 0 0 0 0 var(--ui5-badge-border-color-scheme-7);
  }

  92% {
    opacity: 1;
    box-shadow: 0 0 0 0 var(--ui5-badge-border-color-scheme-7);
  }

  93% {
    opacity: 0.4;
    box-shadow: 0 0 2px 2px var(--ui5-badge-border-color-scheme-7);
  }

  94% {
    opacity: 0.2;
    box-shadow: 0 0 5px 5px var(--ui5-badge-border-color-scheme-7);
  }

  96% {
    opacity: 1;
    box-shadow: 0 0 0 0 var(--ui5-badge-border-color-scheme-7);
  }

  100% {
    opacity: 1;
    box-shadow: 0 0 0 0 var(--ui5-badge-border-color-scheme-7);
  }
}

.sapUiForm .sapMCb {
  padding-left: 1.75rem;
  padding-right: 0.5rem;
}

.sapUiFormEdit.sapUiFormM.sapUiSizeCompact
  .sapUiRespGridMedia-Std-Tablet.sapUiFormResGridCont {
  padding-bottom: 0;
}

.sapUiFormEdit.sapUiFormM.sapUiSizeCompact
  .sapUiRespGridMedia-Std-Desktop.sapUiFormResGridCont
  > .sapUiFormElementLbl:not(.sapUiRespGridSpanL12),
.sapUiFormEdit.sapUiFormM.sapUiSizeCompact
  .sapUiRespGridMedia-Std-Tablet.sapUiFormResGridCont
  > .sapUiFormElementLbl:not(.sapUiRespGridSpanL12),
.sapUiFormEdit.sapUiFormM.sapUiSizeCompact
  .sapUiRespGridMedia-Std-LargeDesktop.sapUiFormResGridCont
  > .sapUiFormElementLbl:not(.sapUiRespGridSpanXL12) {
  padding-top: 0.5rem;
}

.sapUiForm.sapUiFormEdit.sapUiSizeCompact .sapMObjectNumber,
.sapUiForm.sapUiFormEdit.sapUiSizeCompact .sapMText {
  line-height: 1rem;
  padding-top: 0.5rem;
  margin-top: 0;
}

.sapUiForm.sapUiFormEdit.sapUiSizeCompact .sapMText {
  min-height: 1rem;
  padding-bottom: 0.25rem;
}

.sapUiForm.sapUiFormEdit.sapUiSizeCompact
  .sapMObjectNumber
  .sapMObjectNumberUnit {
  line-height: 1rem;
}

.sapUiForm.sapUiFormEdit.sapUiSizeCompact
  .sapMObjectNumber
  .sapMObjectNumberText {
  line-height: 1rem;
}

.sapUiForm.sapUiFormEdit.sapUiSizeCompact .sapMRbG > .sapMRb {
  padding: 0;
}

.sapUiForm.sapUiFormEdit.sapUiSizeCompact .sapMRbG > .sapMRb > .sapMRbB {
  height: 1.9rem;
  line-height: 1.9rem;
}

.sapUiForm.sapUiFormEdit.sapUiSizeCompact
  .sapMRbG
  > .sapMRb
  > .sapMRbB
  > .sapMRbSvg {
  margin: 0.45rem;
}

.sapUiForm.sapUiFormEdit.sapUiSizeCompact .sapMRbG > .sapMRb > .sapMLabel {
  padding-left: 1.7rem;
  height: 1.9rem;
  line-height: 1.9rem;
}

.sapUiForm.sapUiFormEdit.sapUiSizeCompact .sapMCb.sapMCbHasLabel {
  padding-left: 1.7rem;
}

.sapMNLGroup.sapUiSizeCompact .sapMNLIB {
  padding-top: 0.3rem;
  padding-bottom: 0;
}

.sapMNLGroup.sapUiSizeCompact .sapMNLIB .sapMNLIContent {
  justify-content: center;
}

.sapMNLGroup.sapUiSizeCompact .sapMNLIB .sapMNLIFooter {
  padding-top: 0;
}

.sapMITB.ft-height-100-for-codeeditor {
  height: 100%;
}

.sapMITB.ft-height-100-for-codeeditor .sapMITBContainerContent {
  height: 100%;
}

.sapMITB.ft-height-100-for-codeeditor .sapMITBContainerContent .sapMITBContent {
  height: 100%;
}

.ft-label-word-break .sapMLabel > .sapMLabelTextWrapper > bdi {
  word-break: keep-all;
}

.ft_display_bottom_dash_on_table {
  border-bottom: 1px dashed var(--sapIllus_Layering1);
}

.sapUiFormEdit.sapUiSizeCompact .sapMLnk {
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
}

.sapMFlexItem.allow-scroll {
  overflow-y: auto;
}

div.ft-height-fit-content {
  height: fit-content;
  min-height: auto;
}

div.ft-height-fill-available {
  height: stretch;
  min-height: fit-content;
}

div.ft-height-100p {
  height: fit-content;
  min-height: 100%;
}

.ft-sticky-columns .sapUiTableColHdrCnt {
  position: sticky;
  top: 0;
  z-index: 1;
}

.ft-sticky-columns .sapUiTableCnt {
  overflow: visible;
}

.ft-form-elements-overflow-visible
  div.sapUiRespGridOverflowHidden[class*="sapUiRespGrid"]
  > div[class*="sapUiRespGridSpan"] {
  overflow: visible;
}

.ft-selected-icon-tab
  .sapMITH
  .sapMITBHorizontal.sapMITBFilter
  .sapMITBFilterWrapper
  .sapMITBContentArrow {
  width: 100%;
}

.sapMTB.ft-sticky {
  position: sticky;
  top: 0;
  z-index: 2;
}

[class*="sapUiTheme-sap_horizon"] .sapMCbBg {
  left: 1px;
}

[class*="sapUiTheme-sap_horizon"] .sapMPage > .sapMPageHeader + section {
  top: 3.5rem;
}

.ft-image-max-width-100 {
  max-width: 100%;
}

#containerPage > div.sapMScrollContScroll {
  height: 100%;
}

.ft-show-above-react-footer {
  top: calc(100% - var(--sapElement_Height) * 2.5);
  right: 0.4rem;
  position: fixed;
  z-index: 1;
}

.ft-left-bold-border {
  border-left: calc(var(--sapButton_BorderWidth) * 3) solid
    var(--sapButton_BorderColor);
}

.chatbot-dialog {
  width: 25rem;
  height: 35rem;
}

.chatbot-dialog ui5-busy-indicator {
  width: 100%;
  height: 100%;
}

.chatbot-container {
  width: 100%;
  height: 100%;
}

.chatbot-container ui5-busy-indicator {
  width: 100%;
  height: 100%;
}

#chatbot {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  align-items: center;
}

#chatbot .chat-history {
  height: calc(100% - 2.75rem);
  width: 100%;
  overflow-y: scroll;
  align-items: center;
  border-bottom: 1px solid var(--sapContent_ForegroundBorderColor);
}

#chatbot .chat-history ui5-timeline {
  height: fit-content;
  width: 100%;
}

#chatbot .chat-history ui5-timeline ui5-timeline-item {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

#chatbot .chat-history ui5-timeline ui5-timeline-item .content {
  white-space: pre-wrap;
  padding: 0.5rem;
  width: 100%;
  overflow: auto hidden;
  box-sizing: border-box;
  border-top: 1px solid var(--sapContent_ForegroundBorderColor);
}

.chatbot-dialog #chatbot .chat-history ui5-timeline ui5-timeline-item .content {
  width: calc(25rem - 7.5rem);
}

#chatbot .chat-history ui5-timeline ui5-timeline-item[name="You"] .content {
  /* width: fit-content; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chatbot-preview-image img {
  width: 10rem;
  height: 10rem;
}

#chatbot .chat-history ui5-timeline ui5-timeline-item .bottom {
  width: 100%;
  justify-content: flex-start;
  padding-top: 0.5rem;
}

#chatbot .chat-history ui5-timeline ui5-timeline-item[name="You"] .bottom > ui5-select {
  width: 8rem;
  border-radius: 1rem;
  margin-right: 0.5rem;
}

#chatbot .chat-history ui5-timeline ui5-timeline-item[name="You"] .bottom > ui5-link {
  line-height: var(--sapElement_LineHeight);
}

#chatbot .chat-history ui5-timeline ui5-timeline-item .content code {
  background-color: var(--sapShell_Category_9_Background);
  color: var(--sapShell_Category_9_TextColor);
  border-radius: 0.25rem;
  padding: 0 0.25rem;
}

#chatbot .chat-history ui5-timeline ui5-timeline-item .content ol, 
#chatbot .chat-history ui5-timeline ui5-timeline-item .content ul,
#chatbot .chat-history ui5-timeline ui5-timeline-item .content p,
#chatbot .chat-history ui5-timeline ui5-timeline-item .content div,
#chatbot .chat-history ui5-timeline ui5-timeline-item .content pre {
  margin-top: 0;
  margin-bottom: 0;
  display: table;
  width: 100%;
  box-sizing: border-box;
}

#chatbot .chat-history ui5-timeline ui5-timeline-item .content > .modelName {
  margin-bottom: 0.5rem;
}

#chatbot .chat-history ui5-timeline ui5-timeline-item .content > .modelName > span {
  color: var(--sapCriticalElementColor);
}

#chatbot .chat-history .new_session {
  margin-bottom: 0.5rem;
  height: 1rem;
}

#chatbot .chat-bottom-container {
  margin-top: 0.5rem;
  width: fit-content;
  padding: 0.25rem;;
}

#chatbot .chat-inputs-container, #chatbot .chat-control-container {
  height: 2.75rem;
  width: 100%;
}

#chatbot .chat-inputs-container canvas {
  width: 16rem;
  padding: 0 3rem;
  height: 2rem;
}

#chatbot .chat-inputs-container ui5-input {
  height: 2rem;
  width: 20rem;
  margin: 0;
}

#chatbot .chat-inputs-container ui5-button.microphone,
#chatbot .chat-inputs-container ui5-button.stop {
  margin: 0 0.25rem;
  border-radius: 0.5rem;
}

#chatbot .chat-inputs-container ui5-button, #chatbot .chat-control-container ui5-button {
  height: 2rem;
}

#chatbot .chat-control-container ui5-button.send {
  margin-right: 0.25rem;
}

#openRecorderBtn {
  border: none;
}

#openRecorderBtn::part(icon) {
  width: 80%;
  height: 80%;
}

#openRecorderBtn::part(icon)::part(root) {
  width: 100%;
  height: 100%;
}

ui5-form::part(layout) {
  padding: 0;
}

ui5-form::part(column) {
  padding: 0.5rem 0;
}

ui5-form-item::part(label) {
  padding-inline-end: 0;
}